import React,{useEffect,useContext} from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { MdOutlineDashboard } from "react-icons/md";
import { PiUsersFourFill } from "react-icons/pi";
import { AiOutlineProduct } from "react-icons/ai";
import { TbBrandCodesandbox } from "react-icons/tb";
import { MdOutlineInventory2 } from "react-icons/md";
import { TbReceiptTax } from "react-icons/tb";
import { FaHospitalUser } from "react-icons/fa6";
import { AuthContext } from './authcontext/AuthContext';
import { FaQuoteLeft } from "react-icons/fa";
import { LuMailQuestion } from "react-icons/lu";
import Sidebar from './Sidebar'
import { FaChalkboardUser } from "react-icons/fa6";

const Dashboard = () => {
  const {currentUser} = useContext(AuthContext)
  const permissions =currentUser?.permissions
  const navigate = useNavigate()
  const token = localStorage.getItem('token')
useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])
  return (
    <div className='dashboard-main'>
        <div className="dashboard-inner">
              <Link to="/admin" className="dashboard-item">
           <MdOutlineDashboard />
            Dashboard
           </Link>

           

           {/* {permissions?.includes("companies-list") &&  */}
           <Link to='/compnies' className="dashboard-item">
           <TbBrandCodesandbox />
            Companies
           </Link>

{/* }      */}

   {/* {permissions?.includes("products-list") &&  */}
           <Link to='/products' className="dashboard-item">
           <AiOutlineProduct />
            Products
           </Link>
           {/* } */}

           {/* {permissions?.includes("inventory-list") &&  */}
           <Link to="/inventories" className="dashboard-item">
           <MdOutlineInventory2 />
           Inventory
           </Link>
           {/* } */}
          
           <Link to="/enquiries" className="dashboard-item">
           <LuMailQuestion />
         Enquiries
           </Link>
           <Link to="/draft-enquiries" className="dashboard-item">
           <LuMailQuestion />
         Draft Enquiries
           </Link>
  
          
           <Link to="/quotations" className="dashboard-item">
           <FaQuoteLeft />
           Quotations
           </Link>

           
           {/* {permissions?.includes("taxes-list") &&  */}
           <Link to="/withholding-texes" className="dashboard-item">
           <TbReceiptTax />
           Taxes
           </Link> 
           {/* } */}
           {/* {permissions?.includes("vendors-list") && */}

           <Link to="/vendors" className="dashboard-item">
           <FaChalkboardUser />
           Vendors
           </Link>
           {/* } */}
           {/* {permissions?.includes("customers-list") &&  */}

           <Link to="/customers" className="dashboard-item">
           <FaHospitalUser/>
           Customer
           </Link>
{/* } */}
        </div>
    </div>
  )
}

export default Dashboard