import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Link, useNavigate } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from 'react-toastify';
import { IoReturnDownBack } from "react-icons/io5";
import { Services } from '../../api/Services'
import { CustomerServices } from '../../api/CustomerService';


const DynamicQuotationForm = () => {

  const generateUniqueId = (specificNumber, uniqueString) => {
    const timestamp = Date.now(); // Current timestamp in milliseconds
    const randomNum = Math.floor(Math.random() * 100000); // Random number for uniqueness
    return `${specificNumber}-${uniqueString}-${timestamp}-${randomNum}`;
  }

  const navigate = useNavigate()
  const [loading, setIsLoading] = useState(false)
  const [inventories, setInventories] = useState([])
  const [customers, setCustomers] = useState([])
  const [quotations, setQuotations] = useState([
    {
      product_name: "",
      p_name: "",
      inventries_list: inventories,
      inventory_detail: {},
      items: [
        {
          brand_name: "",
          model_name: "",
          price: "",
          description: "",
          quantity: 0,
          price_excluding_sales_tax: 0,
          unit_of_measure: "",
        }
      ],
    },
  ]);


  const [customer, setCustomer] = useState(null);
  const [status, setStatus] = useState(null);
  const [validity_extendable, setValidityExtendable] = useState(null);
  const [quotation_no, setQuotationNo] = useState(generateUniqueId(123, "ABC"));
  const [enquiry_tender_reff, setEnquiryTenderReff] = useState(null);
  const [validity_date, setDeliveryDate] = useState(null);
  const [quotation_date, setQuotationDate] = useState(null);
  
  const [document_generate_by, setDocumentGenerateBy] = useState(0);
  const [document_checked_by, setDocumentCheckedBy] = useState(0);
  const [document_approved_by, setDocumentApprovedBy] = useState(0);
  const [business_terms, setBusinessTerms] = useState(`Prices : Prices are valid only for ready stock only,above prices are quoted exclusive sales tax only.
Delivery : Ready stock prior to sale Unless otherwise stated above,in case of stock end equilent product will be deliver.
Warranties or guarantees : Description of Warrenty or Guarntee of any product is written with specification.
Payment : 100% in advance / At the time of Delivery / As Agreement`);



  useEffect(() => {
    getInventoriesList()
    getCustomersList();
  }, [])
  const getInventoriesList = async () => {
    setIsLoading(true);
    const result = await Services.getInventories()
    console.log(result)
    if (result?.status === 200) {
      setInventories(result?.data?.data?.inventories)

    }
    else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  }

  const getCustomersList = async () => {

    setIsLoading(true);
    const result = await CustomerServices.getCustomers()
    console.log(result)
    if (result?.status === 200) {

      setCustomers(result?.data?.data?.customers)

    }
    else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);
  }

  const handleQuotationChange = async (index, event) => {

    const updatedQuotations = [...quotations];
    updatedQuotations[index].product_name = event.target.value;
    let p_name = '';
    if(event.target && event.target.selectedOptions && event.target.selectedOptions[0] && event.target.selectedOptions[0].getAttribute('data-product-name')){
      p_name = event.target.selectedOptions[0].getAttribute('data-product-name');
    }

    updatedQuotations[index].p_name = p_name;
    

    setIsLoading(true);
    const result = await Services.getInventoryDetail(event.target.value);
    console.log(result);
    if (result?.status === 200) {

      updatedQuotations[index].inventory_detail = result?.data?.data;
      updatedQuotations[index].items[0].description = updatedQuotations[index]?.inventory_detail?.products_to_list?.description;
      updatedQuotations[index].items[0].price = updatedQuotations[index]?.inventory_detail?.sale_price;
      updatedQuotations[index].items[0].price_excluding_sales_tax = updatedQuotations[index]?.inventory_detail?.sale_price;
      updatedQuotations[index].items[0].quantity = updatedQuotations[index]?.inventory_detail?.quantity;
      updatedQuotations[index].items[0].unit_of_measure = updatedQuotations[index]?.inventory_detail?.products_to_list?.unit_of_measure;


    } else {
      toast.error(result?.data?.message, {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        position: toast.POSITION.TOP_CENTER,
      });
    }
    setIsLoading(false);



    setQuotations(updatedQuotations);


  };

  const handleItemChange = (qIndex, iIndex, event) => {
    const { name, value } = event.target;
    const updatedQuotations = [...quotations];
    updatedQuotations[qIndex].items[iIndex][name] = value;
    setQuotations(updatedQuotations);
  };

  const handleDescriptionChange = (qIndex, iIndex, value) => {
    const updatedQuotations = [...quotations];
    updatedQuotations[qIndex].items[iIndex].description = value;
    setQuotations(updatedQuotations);
  };

  const addQuotation = () => {
    setQuotations([
      ...quotations,
      { product_name: "", items: [{ brand_name: "", model_name: "", price: "", description: "" }] },
    ]);
  };

  const removeQuotation = (index) => {
    if (index === 0) return; // Prevent removing the first quotation
    setQuotations(quotations.filter((_, i) => i !== index));
  };

  const addItem = (qIndex, inventory_detail) => {
    const updatedQuotations = [...quotations];
    updatedQuotations[qIndex].items.push({
      brand_name: "",
      model_name: "",
      price: inventory_detail?.sale_price,
      description: inventory_detail?.products_to_list?.description,
      quantity: inventory_detail?.quantity,
      price_excluding_sales_tax: 0,
      unit_of_measure: inventory_detail?.unit_of_measure,
    });

    setQuotations(updatedQuotations);
  };

  const removeItem = (qIndex, iIndex) => {
    if (iIndex === 0) return; // Prevent removing the first item in each quotation
    const updatedQuotations = [...quotations];
    updatedQuotations[qIndex].items = updatedQuotations[qIndex].items.filter((_, i) => i !== iIndex);
    setQuotations(updatedQuotations);
  };

  const handleSubmit = async (is_publish = 1) => {
    console.log('============== ', is_publish);
    if(is_publish){
      setStatus('Approval in process');
    }else{
      setStatus('Draft');
    }
    let quotation_data = {};

    let quotation_items = [];
    quotations.forEach((quotation, index) => {
      quotation_items.push({
        product_name: quotation.product_name,
        items: quotation.items
      });
    });


    quotation_data = {
      customer: customer,
      status: is_publish == 1 ? 'Approval in process' : 'Draft',
      validity_extendable: validity_extendable,
      quotation_no: quotation_no,
      enquiry_tender_reff: enquiry_tender_reff,
      validity_date: validity_date,
      quotation_date: quotation_date,
      document_generate_by: document_generate_by,
      document_checked_by: document_checked_by,
      document_approved_by: document_approved_by,
      business_terms: business_terms,
      quotations_products: quotation_items,
      is_publish: is_publish
    };
    
    console.log('quotation_data ========== ', quotation_data);
    const result = await Services.createQuotations(quotation_data);
    navigate(`/update-quotations/${result.data?.data?.guid}`);

  };




  return (
    <div className='create-company dark-theme py-3'>
      <Link to='/quotations' className="back">
        <IoReturnDownBack /> Go to quotations   </Link>
      <ToastContainer />
      <h2 className="text-center mb-4">Quotation Form</h2>
      <div className="container-small create-company form-wrapper">
        <div className="row">
          <div className="col-12 col-md-8">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4 mb-3">
                <label className="form-label ">Customer</label>
                <select name="customer" id="customer" className="custom-select" required value={customer} onChange={(e) => setCustomer(e.target.value)}>
                  <option value="">Please select</option>
                  {customers?.map((customer) => <option value={customer?.id}>{customer?.name}</option>)}
                </select>
              </div>
              {/* <div className="col-12 col-md-6 col-lg-4 mb-3">
                <label className="form-label ">Status</label>
                <select name="status" id="status" className="custom-select" required value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="">Please select</option>
                  <option value="draft"> Draft</option>
                  <option value="checked"> Checked</option>
                  <option value="approved"> Approved</option>
                  <option value="submitted"> Submitted</option>
                </select>
              </div> */}
              <div className="col-12 col-md-6 col-lg-4 mb-3">
                <label className="form-label ">Validity Extendable</label>
                <select name="validity_extendable" id="validity_extendable" className="custom-select" required value={validity_extendable} onChange={(e) => setValidityExtendable(e.target.value)}>
                  <option value="">Please select</option>
                  <option value="1"> Yes</option>
                  <option value="0"> Not-Extendable</option>
                </select>
              </div>
              
              <div className="col-12 col-md-6 col-lg-4 mb-3">
                <label className="form-label ">Quotation No</label>
                <input type="text" className="input" id="quotation_no" name="quotation_no" value={quotation_no} placeholder="Enter quotation no" required disabled readOnly />
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-3">
                <label className="form-label ">Enquiry Tender Reff</label>
                <input type="text" className="input" id="enquiry_tender_reff" name="enquiry_tender_reff" value={enquiry_tender_reff} placeholder="Enter enquiry tender reff" required onChange={(e) => setEnquiryTenderReff(e.target.value)} />
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-3">
                <label className="form-label ">Validity Date</label>
                <input type="date" className="input" id="validity_date" name="validity_date" value={validity_date} placeholder="Enter validity date" required onChange={(e) => setDeliveryDate(e.target.value)} />
              </div>
              <div className="col-12 col-md-6 col-lg-4 mb-3">
                <label className="form-label ">Quotation date</label>
                <input type="date" className="input" id="quotation_date" name="quotation_date" value={quotation_date} placeholder="Enter validity date" required onChange={(e) => setQuotationDate(e.target.value)} />
              </div>

              <div className="col-12 col-md-12 col-lg-12 mb-3">
                <label className="form-label ">Business Terms</label>
                <ReactQuill className="" value={business_terms} onChange={(value) => setBusinessTerms(value)} /> 
              </div>
             
            </div>

            {quotations?.map((quotation, qIndex) => (
              <div key={qIndex} className="form-wrapper-small">
                <h2 className="my-3 product_name" > {quotations ? `Product ${qIndex + 1}` : ""}</h2>
                <div className="mb-3">
                  <label className="form-label ">Product Name</label>
                  <select name="product_name" id="product_name" className="custom-select" value={quotation.product_name} onChange={(e) => handleQuotationChange(qIndex, e)} required>
                    <option value="">Please select</option>
                    {inventories?.map((inv, key) => <option key={key} value={inv?.id} data-product-name={inv?.products_to_list?.products?.name}
                    >
                      {inv?.products_to_list?.products?.name}
                    </option>)}
                  </select>
                </div>

                {quotation.items.map((item, iIndex) => (

                  <div key={iIndex} className="">
                    <h2 className="my-3 option_name" > {iIndex > 0 ? `Option ${qIndex + 1}.${iIndex}` : ""}</h2>
                    <div className="row">


                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label ">Brand Name</label>
                        <select name="brand_name" id="brand_name" className="custom-select" value={item.brand_name} onChange={(e) => handleItemChange(qIndex, iIndex, e)} required>
                          <option value="">Please select</option>
                          {quotation?.inventory_detail?.brands?.map((brand) => <option value={brand.id}>{brand.name}</option>)}
                        </select>

                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label ">Model Name</label>
                        <select name="model_name" id="model_name" className="custom-select" value={item.model_name} onChange={(e) => handleItemChange(qIndex, iIndex, e)} required>
                          <option value="">Please select</option>
                          {quotation?.inventory_detail?.models?.map((model) => <option value={model}>{model}</option>)}
                        </select>

                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label ">Price</label>
                        <input
                          type="number"
                          className="input"
                          name="price"
                          value={item.price}
                          onChange={(e) => handleItemChange(qIndex, iIndex, e)}
                          placeholder="Enter price"
                          required
                        />
                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label ">Price Excluding Sales Tax</label>
                        <input
                          type="number"
                          className="input"
                          name="price_excluding_sales_tax"
                          value={item.price_excluding_sales_tax}
                          onChange={(e) => handleItemChange(qIndex, iIndex, e)}
                          placeholder="Enter price excluding sales tax"
                          required
                        />
                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label ">Quantity</label>
                        <input
                          type="number"
                          className="input"
                          name="quantity"
                          value={item.quantity}
                          onChange={(e) => handleItemChange(qIndex, iIndex, e)}
                          placeholder="Enter quantity"
                          required
                        />
                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label ">Unit of Measure</label>
                        <input
                          type="text"
                          className="input"
                          name="unit_of_measure"
                          value={item.unit_of_measure}
                          onChange={(e) => handleItemChange(qIndex, iIndex, e)}
                          placeholder="Enter unit of measure"
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-2">
                      <label className="form-label ">Description</label>
                      <ReactQuill className=""
                        value={item.description}
                        onChange={(value) => handleDescriptionChange(qIndex, iIndex, value)}
                      />
                    </div>
                    <hr className="my-4" />
                    {iIndex !== 0 && (
                      <button
                        type="button"
                        className="button my-3"
                        onClick={() => removeItem(qIndex, iIndex)}
                      >
                        Remove Option
                      </button>
                    )}
                  </div>
                ))}
                <div className="d-flex justify-content-center gap-3  my-4">
                  <button type="button" className="button " onClick={() => addItem(qIndex, quotation?.inventory_detail)}>
                    Add Option
                  </button>
                  {qIndex !== 0 && (
                    <button type="button" className="button " onClick={() => removeQuotation(qIndex)}>
                      Delete Product
                    </button>
                  )}
                </div>
              </div>
            ))}
            <hr />
            <div className="d-flex justify-content-center gap-3  my-4">
              <button type="button" className="button" onClick={addQuotation}>
                Add New Product
              </button>
            </div>
            <hr />
            <div className="d-flex justify-content-center gap-3 my-4">
              <button type="button" className="button" onClick={() => handleSubmit(1)}>Save</button>
              <button type="button" className="button" onClick={() => handleSubmit(0)}>Save as Draft</button>
            </div>

          </div>
          <div className="col-12 col-md-4">
            <div className="quotation-view">
              <div className="products mb-3">
                {/* product loop here  */}
                {/* single product here  */}
                {quotations?.map((quotation, qIndex) => (
                <div className="single-product">
                  <div className="product">
                    { quotation?.p_name }
                  </div>
                  {/* options loop here  */}
                  <div className="options-wrapper">

                    {/* single option here  */}
                    {quotation.items.map((item, iIndex) => (
                    <div className="single-option">
                      <div className="option">
                        {qIndex+1}.{(iIndex+1)} = { item?.brand_name }
                      </div>
                      <div className="description">
                        { item?.description }
                      </div>
                    </div>
                    ))}
                  </div>
                </div>
                ))}
              </div>
              <div className="d-flex justify-content-center">
                <button className="button">
                  Print Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default DynamicQuotationForm;
