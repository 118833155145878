import React, { useEffect,useState,useContext } from 'react'
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from 'react-router-dom';
import { IoReturnDownBack } from "react-icons/io5";
import { GrView } from "react-icons/gr";

import DataTable from 'react-data-table-component';
import { BiBorderRadius } from 'react-icons/bi';
import { AuthContext } from '../authcontext/AuthContext';
import { Services } from '../../api/Services';

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";



const Quotations = () => {
  const {currentUser} = useContext(AuthContext)
  const userType = currentUser?.roles[0]?.name
  const permissions =currentUser?.permissions
  const [dataa,setDataa]=useState([])
  const [loading, setLoading] = useState(false);
  const getQuotationsApi= async () => {
    
  
    try {
         const result = await Services.getQuotations();
       
         
        
        if(result?.data){
        
          const transformedQuries =result?.data?.data?.quotations?.map(enq => ({
            id: enq.id,
            guid: enq.guid,
            quotation_no: enq.quotation_no,
            customerName: enq.customer?.name,
            status: enq.status,
            enquiry_tender_reff :enq.enquiry_tender_reff,
            delivery_date :enq.delivery_date,

          
        }));
        setDataa(transformedQuries)
          
        } 
       
       
       
    } catch (error) {
      toast.error(error?.result?.data?.errors[0], {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        
      });
     
      
    }
    finally {
      setLoading(false)
    }
  };


  
  useEffect(()=>{
    getQuotationsApi()
  },[])
  //delete user 
  const deleteEnquiryFunction = async (id) => {
    setLoading(true)
  
    try {
         const result = await Services.deleteEnquiry(id);
        
         
        
        if(result?.data){
      
          toast.error("enquiry deleted !", {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            
          });
          getQuotationsApi()
        } 
        else {
        
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            
          });
        }
       
       
    } catch (error) {
      toast.error(error?.result?.data?.errors[0], {
        autoClose: 2000,
        pauseOnHover: true,
        draggable: true,
        
      });
     
      
    }
    finally {
      setLoading(false)
    }
  };

    const columns = [
        {
            name: 'Quotation No',
            selector: row => row.quotation_no,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: row => row.customerName,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Enquiry Tender Reff',
            selector: row => row.enquiry_tender_reff,
            sortable: true,
        },
        {
          name: 'Validity Date',
          selector: row => row.validity_date,
          sortable: true,
      },
        {
            name: 'Action',
            
            cell: row => (
                <>
               {/* {permissions?.includes("roles-update") &&     */}
                <Link className='button-icon' to={`/update-quotations/${row.guid}`} >
                        <FiEdit />
                    </Link>
                     {/* } */}
                    {/* {permissions?.includes("roles-detail") &&    */}
                     <Link className='button-icon'  to={`/enquiry-detail/${row.id}`}>
                        <GrView  />
                    </Link>
                    {/* } */}
                    {/* {permissions?.includes("roles-delete") &&  */}
                     <div className='button-icon' onClick={() => deleteEnquiryFunction(row.id)}>
                        <MdDeleteOutline />
                    </div> 
                    {/* } */}
                    
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
  
 
  // Function to handle edit action
const handleEdit = (id) => {
    // Add your edit logic here
    console.log(`Edit action for id ${id}`);
};

// Function to handle delete action

  return (
    <div className='create-company dark-theme py-3'>
      {loading && <div className="loader-parent"><div className="_loader"></div></div>}
      <ToastContainer />
     
    
 
   
    <div className="container">
    <div className="d-flex gap-3 w-100">
        <Link to='/dashboard' className="d-flex gap-2 align-items-center">
        <IoReturnDownBack  /> Back to dashboard
    </Link>
    <Link to='/draft-quotations' className="d-flex gap-2 align-items-center">
        <IoReturnDownBack  /> Draft Quotations
    </Link>
        </div>
    <div className="row">
        <div className="col-12">
        <div className="d-flex justify-content-end mb-3">
      {/* {permissions?.includes("roles-store") &&  */}
      <Link to={'/create-quotation'} className="button">Create Quotation</Link> 
      {/* } */}
      </div>
          <DataTable className="bg-white p-4"
			columns={columns}
			data={dataa}
     
      pagination
            paginationPerPage={10} 
            paginationRowsPerPageOptions={[5, 10, 20]}
           
           
		/>
        </div>
    </div>
    </div>
      </div>
    
    
  )
}

export default Quotations