import React, { useEffect, useState } from 'react'
import { Services } from '../../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CustomerServices } from '../../api/CustomerService';

import Sidebar from '../Sidebar';
import { IoReturnDownBack } from "react-icons/io5";
import ProductBar from '../ProductBar';
import ReactQuill from 'react-quill';
import { useNavigate, useParams,Link } from "react-router-dom";
import { IoIosClose } from "react-icons/io";
import 'react-quill/dist/quill.snow.css';
const UpdateQuotation = () => {

  const generateUniqueId = (specificNumber, uniqueString) => {
    const timestamp = Date.now(); // Current timestamp in milliseconds
    const randomNum = Math.floor(Math.random() * 100000); // Random number for uniqueness
    return `${specificNumber}-${uniqueString}-${timestamp}-${randomNum}`;
  }


  const { guid } = useParams();
    const navigate = useNavigate()
    const [customers,setCustomers]=useState([])
    const [loading,setIsLoading]=useState(false)
    const [enquiryDate,setEnquiryDate]=useState('')
    const [customerName,setCustomerName]=useState('')
    const [customerEnquiryReference,setCustomerEnquiryReference]=useState('')
    const [enquiryType,setEnquiryType]=useState('')
    const [quotationSubmitDate,setQuotationSubmitDate]=useState('')
    const [quotationType,setQuotationType]=useState('')
    const [type,setType]=useState('')
    const [imagesList,setImagesList]=useState([])
    const [imagesPreview,setImagesPreview]=useState([])
    const [filesList,setFilesList]=useState([])
    const [filesPreview,setFilesPreview]=useState([])

    const [inventories,setInventories]=useState([])


    const [quotations, setQuotations] = useState([
        {
          product_name: "",
          p_name: "",
          inventries_list: inventories,
          inventory_detail: {},
          items: [
            { 
              brand_name: "",
              model_name: "", 
              price: "", 
              description: "",
              quantity: 0,
              price_excluding_sales_tax: 0,
              unit_of_measure: "",
            }
          ],
        },
      ]);
    
    
    const [customer, setCustomer] = useState(null);
    const [status, setStatus] = useState(null);
    const [validity_extendable, setValidityExtendable] = useState(null);
    const [quotation_no, setQuotationNo] = useState(generateUniqueId(123, "ABC"));
    const [enquiry_tender_reff, setEnquiryTenderReff] = useState(null);
    const [validity_date, setDeliveryDate] = useState(null);
    const [quotation_date, setQuotationDate] = useState(null);
      
    
    const [document_generate_by, setDocumentGenerateBy] = useState(null);
    const [document_checked_by, setDocumentCheckedBy] = useState(null);
    const [document_approved_by, setDocumentApprovedBy] = useState(null);
    const [business_terms, setBusinessTerms] = useState(null);


        const getInventoriesList = async ()=> {
            setIsLoading(true);
            const result = await Services.getInventories()
            console.log(result)
            if (result?.status === 200) {
              setInventories(result?.data?.data?.inventories)    
               
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }

    useEffect(()=>{
      getInventoriesList()
      getCustomersList()
  },[])
      const getCustomersList = async ()=> {
          
              setIsLoading(true);
              const result = await CustomerServices.getCustomers()
              console.log(result)
              if (result?.status === 200) {
              
             setCustomers(result?.data?.data?.customers)
                
              }
              else {
                  toast.error(result?.data?.message, {
                      autoClose: 2000,
                      pauseOnHover: true,
                      draggable: true,
                      position: toast.POSITION.TOP_CENTER,
                    });
              }
              setIsLoading(false);
          }

    const [inputs, setInputs] = useState([ 
        { id:'', product: "", description: "", brand: "",model:"",uom:"",qty:"" },
      ]);
console.log(inputs)
      const handleInputChange = (index, field, value) => {
        const newInputs = [...inputs];
        newInputs[index][field] = value;
        setInputs(newInputs);
      };
    
      const handleFileChange = (index, field, file) => {
        const newInputs = [...inputs];
        newInputs[index][field] = file;
        setInputs(newInputs);
      };
       // add new plan
  const addNewInputs = () => {
    setInputs([
      ...inputs,
      { product: "", description: "", brand: "",model:"",uom:"",qty:"" },
    ]);
  };
  //remove plan
  const removeInputs = (index) => {
    const newInputs = inputs.filter((_, i) => i !== index);
    setInputs(newInputs);
  };
const token = localStorage.getItem('token')


useEffect(()=>{
if(!token){
    navigate('/')
}
},[token])

//handle images changes

//get enquiry details function

const getQuotationDetailApi = async (guid) => {
  setIsLoading(true);
  const result = await Services.getQuotationDetail(guid);
  console.log(result);
  if (result?.status === 200) {
    

    let quotations_detail = result.data.data.quotations;

    // const inventory_detail = await Services.getInventoryDetail(quotations_detail.product_id);
    console.log('inventory_detail == ========= ', quotations_detail.products);
    if(quotations_detail && quotations_detail.products){
      let quotation_details = [];
      let product_items = [];
      quotations_detail.products.forEach((product, index) => {
          if(product && product.items){
            let items = [];
            product.items.forEach((item, i) => {
              items.push({
                brand_name: item.brand_name,
                model_name: item.model_name, 
                price: item.price, 
                description: item.description,
                quantity: item.quantity,
                price_excluding_sales_tax: item.price_excluding_sales_tax,
                unit_of_measure: item.unit_of_measure,
              })
            });

            product_items.push({
              product_name: product.product_name,
              items: items,
              inventory_detail: product.inventory_detail
            })
          }
      });

      setQuotations(product_items);
    }
 
  
    setCustomer(quotations_detail.customer_id);
    setStatus(quotations_detail.status);
    setValidityExtendable(quotations_detail.validity_extendable);
    setQuotationNo(quotations_detail.quotation_no);
    setEnquiryTenderReff(quotations_detail.enquiry_tender_reff);
    setDeliveryDate(quotations_detail.validity_date);
    setQuotationDate(quotations_detail.quotation_date);
    
    
    setDocumentGenerateBy(quotations_detail.document_generate_by);
    setDocumentCheckedBy(quotations_detail.document_checked_by);
    setDocumentApprovedBy(quotations_detail.document_approved_by);
    setBusinessTerms(quotations_detail.business_terms);


    console.log('quotations ======== ', quotations);
  
  } else {
    toast.error(result?.data?.message, {
      autoClose: 2000,
      pauseOnHover: true,
      draggable: true,
      position: toast.POSITION.TOP_CENTER,
    });
  }
  setIsLoading(false);
};
useEffect(() => {
  getQuotationDetailApi(guid);
}, []);

const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const filePreviews = files.map(file => URL.createObjectURL(file));
    setImagesPreview(prevImages => [...prevImages, ...filePreviews]);
    setImagesList(prevFiles => [...prevFiles, ...files]);
  };

//handle remove image
const removeImage = (imageToRemove, indexToRemove) => {
    setImagesPreview(prevImages => prevImages.filter(image => image !== imageToRemove));
    setImagesList(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    URL.revokeObjectURL(imageToRemove); // Revoke the object URL
  };
  useEffect(() => {
    return () => {
      // Revoke the object URLs to avoid memory leaks
      imagesList.forEach(url => URL.revokeObjectURL(url));
    };
  }, [imagesList]);
//handle files changes


const handleFilesChange = (e) => {
    const files = Array.from(e.target.files);
    const filePreviews = files.map(file => URL.createObjectURL(file));
    setFilesPreview(prevImages => [...prevImages, ...filePreviews]);
    setFilesList(prevFiles => [...prevFiles, ...files]);
  };

//handle remove image
const removeFile = (imageToRemove, indexToRemove) => {
    setFilesPreview(prevImages => prevImages.filter(image => image !== imageToRemove));
    setFilesList(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    URL.revokeObjectURL(imageToRemove); // Revoke the object URL
  };
  useEffect(() => {
    return () => {
      // Revoke the object URLs to avoid memory leaks
      filesList.forEach(url => URL.revokeObjectURL(url));
    };
  }, [filesList]);

//add categor


 


 
    

   


     const handleQuotationChange = async(index, event) => {
        
        const updatedQuotations = [...quotations];
        updatedQuotations[index].product_name = event.target.value;

        let p_name = '';
        if(event.target && event.target.selectedOptions && event.target.selectedOptions[0] && event.target.selectedOptions[0].getAttribute('data-product-name')){
          p_name = event.target.selectedOptions[0].getAttribute('data-product-name');
        }
        updatedQuotations[index].p_name = p_name;
    
        setIsLoading(true);
        const result = await Services.getInventoryDetail(event.target.value);
        console.log(result);
        if (result?.status === 200) {
    
          updatedQuotations[index].inventory_detail = result?.data?.data;
          updatedQuotations[index].items[0].description = updatedQuotations[index]?.inventory_detail?.products_to_list?.description;
          updatedQuotations[index].items[0].price = updatedQuotations[index]?.inventory_detail?.sale_price;
          updatedQuotations[index].items[0].quantity = updatedQuotations[index]?.inventory_detail?.quantity;
          updatedQuotations[index].items[0].unit_of_measure = updatedQuotations[index]?.inventory_detail?.unit_of_measure;
          
    
        } else {
          toast.error(result?.data?.message, {
            autoClose: 2000,
            pauseOnHover: true,
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          });
        }
        setIsLoading(false);
    
        
        
        setQuotations(updatedQuotations);
    
        
      };
    
      const handleItemChange = (qIndex, iIndex, event) => {
        const { name, value } = event.target;
        const updatedQuotations = [...quotations];
        updatedQuotations[qIndex].items[iIndex][name] = value;
        setQuotations(updatedQuotations);
      };
    
      const handleDescriptionChange = (qIndex, iIndex, value) => {
        const updatedQuotations = [...quotations];
        updatedQuotations[qIndex].items[iIndex].description = value;
        setQuotations(updatedQuotations);
      };
    
      const addQuotation = () => {
        setQuotations([
          ...quotations,
          { product_name: "", items: [{ brand_name: "", model_name: "", price: "", description: "" }] },
        ]);
      };
    
      const removeQuotation = (index) => {
        if (index === 0) return; // Prevent removing the first quotation
        setQuotations(quotations.filter((_, i) => i !== index));
      };
    
      const addItem = (qIndex, inventory_detail) => {
        const updatedQuotations = [...quotations];
        updatedQuotations[qIndex].items.push({
          brand_name: "",
          model_name: "",
          price: inventory_detail?.sale_price,
          description: inventory_detail?.products_to_list?.description,
          quantity: inventory_detail?.quantity,
          price_excluding_sales_tax: 0,
          unit_of_measure: inventory_detail?.unit_of_measure,
        });
    
        setQuotations(updatedQuotations);
      };
    
      const removeItem = (qIndex, iIndex) => {
        if (iIndex === 0) return; // Prevent removing the first item in each quotation
        const updatedQuotations = [...quotations];
        updatedQuotations[qIndex].items = updatedQuotations[qIndex].items.filter((_, i) => i !== iIndex);
        setQuotations(updatedQuotations);
      };
    
      const handleSubmit = async(is_publish = 1) => {
        let quotation_data = {};
          if(is_publish){
            setStatus('Approval in process');
          }else{
            setStatus('Draft');
          }
        let quotation_items = [];
        quotations.forEach((quotation, index) => {
          quotation_items.push({
            product_name: quotation.product_name,
            items: quotation.items
          });
        });
        
      
      quotation_data = {
                          customer: customer,
                          status: status,
                          validity_extendable: validity_extendable,
                          quotation_no: quotation_no,
                          enquiry_tender_reff: enquiry_tender_reff,
                          validity_date: validity_date,
                          quotation_date: quotation_date,
                          document_generate_by: document_generate_by,
                          document_checked_by: document_checked_by,
                          document_approved_by: document_approved_by,
                          business_terms: business_terms,
                          quotations_products: quotation_items,
                          is_publish: is_publish
                        };

                        
        
        const result = await Services.updateQuotation(guid ,quotation_data);

        navigate(`/quotations`);

      };
    

 


 
    


  return (
    <div className='create-company dark-theme py-3'>
      
    <Link to='/quotations' className="back">
        <IoReturnDownBack  /> Go to Quotations
    </Link>
        <ToastContainer />
    {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}



<div className="container-small create-company form-wrapper">
    <div className="row">
       
        <div className="col-12 col-md-8">
       
    
         <h2 className="title-small text-center">Update Quotation</h2>
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label className="form-label text-black">Customer</label>
                  <select name="customer" id="customer" className="custom-select" required  value={customer} onChange={(e) => setCustomer(e.target.value)}>
                    <option value="">Please select</option>
                    {customers?.map((customer)=> <option value={customer?.id}>{customer?.name}</option>)}
                  </select>
                </div>
                {/* <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label className="form-label text-black">Status</label>
                  <select name="status" id="status" className="custom-select" required value={status} onChange={(e) => setStatus(e.target.value)}>
                    <option value="">Please select</option>
                    <option value="draft"> Draft</option>
                    <option value="checked"> Checked</option>
                    <option value="approved"> Approved</option>
                    <option value="submitted"> Submitted</option>
                  </select>
                </div> */}
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label className="form-label text-black">Validity Extendable</label>
                  <select name="validity_extendable" id="validity_extendable" className="custom-select" required value={validity_extendable} onChange={(e) => setValidityExtendable(e.target.value)}>
                    <option value="">Please select</option>
                    <option value="1"> Yes</option>
                    <option value="0"> Not-Extendable</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label className="form-label text-black">Quotation No</label>
                  <input type="text" className="input" id="quotation_no" name="quotation_no" value={quotation_no} placeholder="Enter quotation no" required disabled readOnly/>
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label className="form-label text-black">Enquiry Tender Reff</label>
                  <input type="text" className="input" id="enquiry_tender_reff" name="enquiry_tender_reff" value={enquiry_tender_reff} placeholder="Enter enquiry tender reff" required onChange={(e) => setEnquiryTenderReff(e.target.value)} />
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label className="form-label text-black">Validity Date</label>
                  <input type="date" className="input" id="validity_date" name="validity_date" value={validity_date} placeholder="Enter validity date" required onChange={(e) => setDeliveryDate(e.target.value)} />
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-3">
                  <label className="form-label text-black">Quotation Date</label>
                  <input type="date" className="input" id="quotation_date" name="quotation_date" value={quotation_date} placeholder="Enter validity date" required onChange={(e) => setQuotationDate(e.target.value)} />
                </div>

                <div className="col-12 col-md-12 col-lg-12 mb-3">
                  <label className="form-label ">Business Terms</label>
                  <ReactQuill className="" value={business_terms} onChange={(value) => setBusinessTerms(value)} /> 
                </div>
              </div>
      
              {quotations.map((quotation, qIndex) => (
                <div key={qIndex} className="form-wrapper-small">
                  <div className="mb-3">
                  <h2 className="my-3 product_name" > {quotations  ? `Product ${qIndex + 1}` : ""}</h2>
                    <label className="form-label text-black">Product Name</label>
                    <select name="product_name" id="product_name" className="custom-select" value={quotation.product_name} onChange={(e) => handleQuotationChange(qIndex, e)} required>
                      <option value="">Please select</option>
                      {inventories?.map((inv, key)=> <option key={key} value={inv?.id} data-product-name={inv?.products_to_list?.products?.name}>
                        {inv?.products_to_list?.products?.name}</option>
                      )}
                    </select>
                  </div>
                  {quotation.items.map((item, iIndex) => (
                    <div key={iIndex} className="">
                       <h2 className="my-3 option_name" > {iIndex > 0 ? `Option ${qIndex + 1}.${iIndex}` : ""}</h2>
                       <div className="row">
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label text-black">Brand Name</label>
                        <select name="brand_name" id="brand_name" className="custom-select" value={item.brand_name} onChange={(e) => handleItemChange(qIndex, iIndex, e)} required>
                          <option value="">Please select</option>
                          {quotation?.inventory_detail?.brands?.map((brand)=> <option value={brand.id}>{brand.name}</option>)}
                        </select>
                       
                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label text-black">Model Name</label>
                        <select name="model_name" id="model_name" className="custom-select" value={item.model_name} onChange={(e) => handleItemChange(qIndex, iIndex, e)} required>
                          <option value="">Please select</option>
                          {quotation?.inventory_detail?.models?.map((model)=> <option value={model}>{model}</option>)}
                        </select>
                       
                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label text-black">Price</label>
                        <input
                          type="number"
                          className="input"
                          name="price"
                          value={item.price}
                          onChange={(e) => handleItemChange(qIndex, iIndex, e)}
                          placeholder="Enter price"
                          required
                        />
                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label text-black">Price Excluding Sales Tax</label>
                        <input
                          type="number"
                          className="input"
                          name="price_excluding_sales_tax"
                          value={item.price_excluding_sales_tax}
                          onChange={(e) => handleItemChange(qIndex, iIndex, e)}
                          placeholder="Enter price excluding sales tax"
                          required
                        />
                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label text-black">Quantity</label>
                        <input
                          type="number"
                          className="input"
                          name="quantity"
                          value={item.quantity}
                          onChange={(e) => handleItemChange(qIndex, iIndex, e)}
                          placeholder="Enter quantity"
                          required
                        />
                      </div>
                      <div className="mb-2 col-12 col-md-4">
                        <label className="form-label text-black">Unit of Measure</label>
                        <input
                          type="text"
                          className="input"
                          name="unit_of_measure"
                          value={item.unit_of_measure}
                          onChange={(e) => handleItemChange(qIndex, iIndex, e)}
                          placeholder="Enter unit of measure"
                          required
                        />
                      </div>
                      </div>
                      <div className="mb-2">
                        <label className="form-label text-black">Description</label>
                        <ReactQuill className="text-black"
                          value={item.description}
                          onChange={(value) => handleDescriptionChange(qIndex, iIndex, value)}
                        />
                      </div>
                      
                      {iIndex !== 0 && (
                        <div className="my-3">
                          <button
                          type="button"
                          className="button my-2"
                          onClick={() => removeItem(qIndex, iIndex)}
                        >
                          Remove Item
                        </button>
                        </div>
                      )}
                    </div>
                  ))}
                <div className="d-flex justify-content-center gap-3  my-4">
                  <button type="button" className="button " onClick={() => addItem(qIndex, quotation?.inventory_detail)}>
                    Add Option
                  </button>
                  {qIndex !== 0 && (
                    <button type="button" className="button " onClick={() => removeQuotation(qIndex)}>
                      Delete Product
                    </button>
                  )}
                </div>
                </div>
              ))}
              <hr />
            <div className="d-flex justify-content-center gap-3  my-4">
              <button type="button" className="button" onClick={addQuotation}>
                Add New Product
              </button>
            </div>
            <hr />
            <div className="d-flex justify-content-center gap-3 my-4">
              <button type="button" className="button" onClick={() => handleSubmit(1)}>Save</button>
              <button type="button" className="button" onClick={() => handleSubmit(0)}>Save as Draft</button>
            </div>
            
            
            </div>
            <div className="col-12 col-md-4">
            <div className="quotation-view">
              <div className="products mb-3">
              {quotations?.map((quotation, qIndex) => (
                <div className="single-product">
                  <div className="product">
                    { quotation?.p_name }
                  </div>
                  {/* options loop here  */}
                  <div className="options-wrapper">

                    {/* single option here  */}
                    {quotation.items.map((item, iIndex) => (
                    <div className="single-option">
                      <div className="option">
                        {qIndex+1}.{(iIndex+1)} = { item?.brand_name }
                      </div>
                      <div className="description">
                        { item?.description }
                      </div>
                    </div>
                    ))}
                  </div>
                </div>
                ))}

              </div>
              <div className="d-flex justify-content-center">
                <button className="button">
                  Print Now
                </button>
              </div>
            </div>
          </div>
            </div>
            </div>
            </div>

  )
}


export default UpdateQuotation