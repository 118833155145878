import React from 'react'
import { useEffect,useState ,useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import { Services } from '../api/Services'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthContext } from './authcontext/AuthContext';

const SelectCompany = () => {
    const {currentUser}=useContext(AuthContext)
    const companies =currentUser?.companies;
    const roleName =currentUser.role_name

    const navigate = useNavigate()
    const [company,setCompany]=useState('')
    const [compnies,setCompnies]=useState([])
    const [loading,setIsLoading]=useState(false)
    useEffect(()=>{
        getCompniesList()
    },[])
    const handleCompany = (e)=>{
        localStorage.setItem("selected-company",e.target.value)
        setCompany(e.target.value)

    }
    const getCompniesList = async ()=> {
        
        setIsLoading(true);
        const result = await Services.getCompnies()
        console.log(result)
        if (result?.status === 200) {
        
       setCompnies(result?.data?.data?.companies)
          
        }
        else {
            toast.error(result?.data?.message, {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
        setIsLoading(false);
    }
    const CompanyFunction = async ()=> {
        if(company === ""){
            toast.error("please select company", {
                autoClose: 2000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
        }
    
        else {
            const formData = new FormData();
            if(company){
                formData.append("company_id",company)
            }
           
            setIsLoading(true);
            const result = await Services.selectCompany(formData)
            console.log(result)
            if (result?.status === 200) {
           
            toast.success(result?.data?.data?.message, {
                autoClose: 1000,
                pauseOnHover: true,
                draggable: true,
                position: toast.POSITION.TOP_CENTER,
              });
             
         navigate('/dashboard')

         
             
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 2000,
                    pauseOnHover: true,
                    draggable: true,
                    position: toast.POSITION.TOP_CENTER,
                  });
            }
            setIsLoading(false);
        }
    
    }

  return (
    <div className="login-form-wrapper form-wrapper">
        <ToastContainer />
         {loading && <div className="loader-wrapper">
        <div className="loader"></div>
    </div>}
    <h1 className="title text-center">
        Please select company {roleName}
    </h1>
    {roleName === 'admin' ? <div className="input-wrapper">
        <select name="" value={company} onChange={(e)=>handleCompany(e)}>
            <option value="">Select company</option>
            {
                compnies.map((com,index)=> 
                    <option key={index} value={com?.id}>
                        {com?.company_name}
                    </option>
                )
            }
        </select>
    </div> : <div className="input-wrapper">
        <select name="" value={company} onChange={(e)=>handleCompany(e)}>
            <option value="">Select company</option>
            {compnies.map((com,index)=> 
                <option key={index} value={com?.id}>
                    {com?.company_name}
                </option>
            )}
        </select>
    </div>}
    
   
    <div className="text-center">
        <button className='button' onClick={()=>CompanyFunction()}>Select company now</button>
    </div>
</div>
  )
}

export default SelectCompany