import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthContextProvider } from './components/authcontext/AuthContext';
import './index.css';
import App from './App';




const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
);

// root.render(
//   <React.StrictMode>
//     <AuthContextProvider>    <App /> </AuthContextProvider >
//   </React.StrictMode>
// );


